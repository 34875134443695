/* eslint-disable no-trailing-spaces */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import logoImagePath from '../images/logo.png';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(135.91deg,#0051F0 0%,#00C9F0 99.99%,#0051F0 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const Heading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 42px;
  color: #FFFFFF;
`;

const Logo = styled.img`
  justify-self: flex-start;
  height: auto;
  width: 400px;
  margin-bottom: 40px;
`;

const LinkStyled = styled(Link)`
  color: #FFF;
`;

const FourOFour = () => (
  <Container>
    <Logo src={logoImagePath} />
    <Heading>
      Page Not Found
    </Heading>
    <Heading>
      <LinkStyled to="/ ">BACK</LinkStyled>
    </Heading>
  </Container>
);

export default FourOFour;
